import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from 'app/services/auth/legfi-jwt.service';
import { BillPayment } from 'app/models/entities/bill-payment';
import { VendorCustomFieldValue } from 'app/models/entities/vendors/vendor-custom-field-value';
import { Transaction } from 'app/models/entities/transactions/transaction';
import { Category } from 'app/models/entities/accounting/category';
import { FixedAsset } from 'app/models/entities/accounting/fixed-asset';
import { Equity } from 'app/models/entities/accounting/equity';
import { Liability } from 'app/models/entities/accounting/liability';
import { Payable } from 'app/models/entities/payable';

export class Vendor
{
    id: number;
    organizationId: number;
    name: string;
    nickname: string;
    accountNumber: string;
    ein: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    is1099Eligible: boolean = false;
    hasLogo: boolean = false;
    vendorLogoUrl: string;
    lastCategoryId: number;
    lastCategoryType: string;
    phone: string;
    fax: string;
    email: string;
    contactName: string;
    website: string;
    mailStopCode: string;
    defaultTransactionOwnerType: string;
    defaultTransactionOwnerId: number;

    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    billPayments: BillPayment[] = [];
    customFieldValues: VendorCustomFieldValue[] = [];
    lastBillPayment: BillPayment;
    lastTransaction: Transaction;
    transactions: Transaction[] = [];
    payables: Payable[] = [];
    category: Category | FixedAsset | Equity | Liability;
    form1099s: [] = [];

    constructor(billPayVendor: any) {
        this.id = billPayVendor.id || 0;
        this.organizationId = billPayVendor.organizationId || 0;
        this.name = billPayVendor.name || '';
        this.nickname = billPayVendor.nickname || '';
        this.accountNumber = billPayVendor.accountNumber || '';
        this.ein = billPayVendor.ein || '';
        this.address1 = billPayVendor.address1 || '';
        this.address2 = billPayVendor.address2 || '';
        this.city = billPayVendor.city || '';
        this.state = billPayVendor.state || '';
        this.zip = billPayVendor.zip || '';
        this.country = billPayVendor.country || '';
        this.is1099Eligible = billPayVendor.is1099Eligible || false;
        this.hasLogo = billPayVendor.hasLogo || false;
        this.vendorLogoUrl = billPayVendor.vendorLogoUrl || '';
        this.lastCategoryId = billPayVendor.lastCategoryId || 0;
        this.lastCategoryType = billPayVendor.lastCategoryType || '';
        this.phone = billPayVendor.phone || '';
        this.fax = billPayVendor.fax || '';
        this.email = billPayVendor.email || '';
        this.contactName = billPayVendor.contactName || '';
        this.website = billPayVendor.website || '';
        this.mailStopCode = billPayVendor.mailStopCode || '';
        this.defaultTransactionOwnerType = billPayVendor.defaultTransactionOwnerType || null;
        this.defaultTransactionOwnerId = billPayVendor.defaultTransactionOwnerId || null;

        const timezone = LegFiJwtService.getTimezone();
        if (billPayVendor.createdAt) {
            this.createdAt = moment.utc(billPayVendor.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (billPayVendor.updatedAt) {
            this.updatedAt = moment.utc(billPayVendor.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (billPayVendor.deletedAt) {
            this.deletedAt = moment.utc(billPayVendor.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (billPayVendor.lastBillPayment) {
            this.lastBillPayment = new BillPayment(billPayVendor.lastBillPayment);
        } else if (billPayVendor.billPayments) {
            this.billPayments = billPayVendor.billPayments.map((b) => new BillPayment(b));
            this.lastBillPayment = this.billPayments[0];
        }

        if (billPayVendor.lastTransaction) {
            this.lastTransaction = new Transaction(billPayVendor.lastTransaction);
        } else if (billPayVendor.transactions) {
            this.transactions = billPayVendor.transactions.map(t => new Transaction(t));
            this.lastTransaction = this.transactions[0];
        }
        if (billPayVendor.payables) {
            this.payables = billPayVendor.payables.map(p => new Payable(p));
        }

        if (billPayVendor.category) {
            switch (this.defaultTransactionOwnerType) {
                case 'App\\Models\\Entities\\FixedAsset':
                    this.category = new FixedAsset(billPayVendor.category);
                    break;
                case 'App\\Models\\Entities\\Equity':
                    this.category = new Equity(billPayVendor.category);
                    break;
                case 'App\\Models\\Entities\\Liability':
                    this.category = new Liability(billPayVendor.category);
                    break;
                default:
                    this.category = new Category(billPayVendor.category);
            }
        }

        if (billPayVendor.customFieldValues) {
            this.customFieldValues = billPayVendor.customFieldValues.map(
                    (customFieldValue) => {
                        return new VendorCustomFieldValue(customFieldValue);
                    },
            ).sort((a, b) => a.customField.name.localeCompare(b.customField.name));
        }

        if (billPayVendor.form1099s) {
            this.form1099s = billPayVendor.form1099s;
        }
    }
}

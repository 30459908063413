export enum AppNavigationOrder
{
    LAUNCHPAD,
    DASHBOARD,
    DOCUMENTS,
    REQUESTS,
    VIOLATIONS,
    MEMBERSHIP_DIRECTORY,
    SURVEYS,
    MESSAGE_BOARDS,
    CALENDAR,
    UNITS,
    PEOPLE,
    INVOICES,
    PAYMENTS,
    PAYABLES,
    VENDORS,
    TRANSACTIONS,
    BUDGETS,
    REPORTS,
    ORG_REQUESTS,
    ORG_VIOLATIONS,
    BROADCAST,
    MAIL_ROOM,
    ORG_DOCUMENTS,
    OTHER,
    HELP,
}

export enum AppMobileNavigationOrder
{
    ORG_SETTINGS,
    MAKE_PAYMENT,
    OWNERS_ACCOUNT,
    MY_ACCOUNT,
    LOG_OUT,
}

export enum AppAdminNavigationOrder
{
    MANAGE_PAYMENTS,
    MANAGE_ORGANIZATIONS,
    LOCKBOX,
    BILLING,
    PRODUCTS,
    PLANS,
    FAILED_JOBS,
    ASSIGNED_SALES_REPS,
}
